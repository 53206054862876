import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import "../styles/404.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="error__container">
      <h1>Page not found</h1>
      <p>Sorry for the inconvenience</p>
      <Link to="/" className="styled-button">
        Back Home
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
